<template>
  <div class="explorer">
    <ExplorerComponent/>
  </div>
</template>

<script>
import ExplorerComponent from '@/components/ExplorerComponent.vue';

export default {
  name: 'EditorView',
  components: {
    ExplorerComponent,
  },
  props: [],
  mounted() {

  },
};
</script>
