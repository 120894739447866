<template>
  <div class="uploadProgress" v-if="uploadRunning">
      <UploadAnimationComponent/>
  </div>
  <div class="exlporer" v-if="!uploadRunning">
    <div class="page-title">
      <div @click="goUp" v-if='currentFolder != null' class='navButton narrow-screen'><i class="fas fa-arrow-up"/> ..</div>
      {{ $t('pageTitle.explorer') }}
      <span class="navButton bgBrown refresh" @click="loadFolderContent"><i class="fas fa-sync"></i></span>
    </div>
    <SpaceIndicatorComponent class="spaceIndicator" ref="spaceIndicator"/>
    <div class="uploadError" v-if="uploadError != null" v-html="uploadError"/>
    <div class="currentLocation" v-if='currentFolder != null && currentFolder.name != null'>
      <div @click="goUp" v-if='currentFolder != null' class='navButton wide-screen'><i class="fas fa-arrow-up"/> ..</div>
      <div class="currentLocationTitle"><i class="far fa-folder-open"/> {{currentFolder.name}}</div>
    </div>
    <div class="currentLocation" v-if='currentFolder == null'>{{ $t('general.root') }}</div>
    <br/>
    <div class="listWrapper explorerWrapper" ref="explorerWrapper" :style='{ height: explorerHeight }' data-cy='explorer-drop'>
      <EmptyListAnimationComponent v-if="folders != null && files != null && folders.length == 0 && files.length == 0"/>
      <div class="listItemWrapper folderTable" v-for="entry in folders" :key="entry._id">
        <div class="listItem explorerFolder" @click="openFolder(entry)"><div class="icon"><i class="far fa-folder"/></div> {{entry.name}}</div>
        <div class="listActionWrapper">
          <span class="listAction explorerAction" data-cy='explorer-edit-folder' v-if="accessRight=='WRITE'"
            @click="editFolder(entry)" :title="$t('action.edit')"><i class="fas fa-pen"/></span>
          <span class="listAction explorerAction" data-cy='explorer-delete-folder' v-if="accessRight=='WRITE'"
            @click="deleteFolder(entry._id)" :title="$t('action.delete')"><i class="fas fa-trash"/></span>
        </div>
      </div>
      <div class="listItemWrapper filesTable" v-for="entry in files" :key="entry._id">
        <div class="listItem explorerFile" @click="openFile(entry)"><div class="icon"><i class="far fa-file"/></div> {{entry.name}}</div>
        <div class="listActionWrapper">
          <span class="listAction explorerAction" @click="openFile(entry, true)" :title="$t('action.openInTab')"><i class="fas fa-window-restore"/></span>
          <span class="listAction explorerAction" @click="downloadFile(entry)" :title="$t('action.download')"><i class="fas fa-cloud-download-alt"/></span>
          <span class="listAction explorerAction" data-cy='explorer-edit-file'
            v-if="accessRight=='WRITE'" @click="editFile(entry)" :title="$t('action.edit')"><i class="fas fa-pen"/></span>
          <span class="listAction explorerAction" data-cy='explorer-delete-file'
            v-if="accessRight=='WRITE'" @click="deleteFile(entry._id)" :title="$t('action.delete')"><i class="fas fa-trash"/></span>
        </div>
      </div>
    </div>
    <div v-if="accessRight=='WRITE'" class="floating-add-button" @click="addFolder">
      <img class="floating-add-button-icon" src="../assets/icons/plus_white.png" />
    </div>
    <ConfirmModal ref="confirmDeleteModal" :title="$t('modal.titleDelete')" :text="$t('modal.textDelete')"/>
    <PromptModal ref="renameFileModal" :title="$t('modal.titleRenameFile')" :text="$t('modal.textRenameFile')"/>
    <CreateOrEditFolderModal :folderToEdit="folderToEdit" :parentFolder="currentFolder" ref="createOrEditFolderModal" @refresh="loadFolderContent"/>
  </div>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import ServiceHelper from '@/helpers/ServiceHelper';
import CryptoHelper from '@/helpers/CryptoHelper';
import CreateOrEditFolderModal from '@/components/modals/CreateOrEditFolderModal.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import PromptModal from '@/components/modals/PromptModal.vue';
import EmptyListAnimationComponent from '@/components/EmptyListAnimationComponent.vue';
import SpaceIndicatorComponent from '@/components/SpaceIndicatorComponent.vue';
import UploadAnimationComponent from '@/components/UploadAnimationComponent.vue';

export default {
  name: 'ExplorerComponent',
  props: [],
  components: {
    CreateOrEditFolderModal,
    ConfirmModal,
    PromptModal,
    EmptyListAnimationComponent,
    SpaceIndicatorComponent,
    UploadAnimationComponent,
  },
  data() {
    return {
      currentFolder: null,
      folders: [],
      files: [],
      accessRight: '',
      dragdropListeners: [],
      folderToEdit: null,
      explorerHeight: '400px',
      uploadRunning: false,
      uploadError: null,
    };
  },
  watch: {
  },
  mounted() {
    this.loadFolderContent();
    window.addEventListener('resize', this.resizeHandler);
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    resizeHandler() {
      this.explorerHeight = `${(window.innerHeight - this.$refs.explorerWrapper.offsetTop - 10)}px`;
    },
    initDragDrop() {
      const uploadElem = this.$refs.explorerWrapper;
      // don't upload files to root!

      this.dragdropListeners.forEach((l) => {
        uploadElem.removeEventListener('drop', l);
      });

      /* events fired on the drop targets */
      uploadElem.addEventListener('dragover', (e) => {
        // prevent default to allow drop
        e.preventDefault();
        e.stopPropagation();
      }, false);

      const newListener = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.currentFolder == null || uploadElem == null) {
          alert(this.$t('error.no_upload_to_root'));
          return;
        }

        const t = this.$store.getters.tenant;
        if (t != null && t.subscriptionType === 'EXPIRED') {
          alert(this.$t('error.code_903'));
          return;
        }

        this.uploadError = null;
        if (this.accessRight !== 'WRITE') {
          alert('No write access to this folder!');
          return;
        }
        this.uploadRunning = true;
        this.$nextTick(async () => {
          const { items } = e.dataTransfer;
          for (let idx = 0; idx < items.length; idx += 1) {
            const i = items[idx];
            const file = i.getAsFile();
            const fileReader = new FileReader();
            fileReader.onload = async (fevent) => {
              try {
                const encrypted = CryptoHelper.encrypt(fileReader.result);
                const url = this.currentFolder != null ? `file/${this.currentFolder._id}` : 'file';
                await ServiceHelper.sendDataToBackend(
                  url,
                  { name: CryptoHelper.encrypt(fevent.target.fileName), type: fevent.target.fileType, content: encrypted },
                  'POST', this.progressCallback,
                );
                this.uploadRunning = false;
                this.loadFolderContent();
              } catch (error) {
                this.uploadError = this.$t('error.upload');
                if (error.errorCode != null) {
                  this.uploadError += '<br/>';
                  this.uploadError += this.$t(`error.code_${error.errorCode}`);
                }
                this.uploadRunning = false;
                console.error(error);
              }
            };
            fileReader.fileName = file.name;
            fileReader.fileType = file.type;
            fileReader.readAsArrayBuffer(file);
          }
        });
      };
      this.dragdropListeners.push(newListener);
      uploadElem.addEventListener('drop', newListener);
    },
    progressCallback(progress) {
      console.log(progress);
    },
    async loadFolderContent() {
      this.uploadError = null;
      if (this.$refs.spaceIndicator != null) this.$refs.spaceIndicator.refresh();
      this.files = [];
      this.folders = [];
      try {
        const url = this.currentFolder != null ? `folder/${this.currentFolder._id}` : 'folder';
        const data = await ServiceHelper.loadDataFromBackend(url);
        data.payload.folders.forEach((entry) => {
          if (entry.name != null) {
            const e = entry;
            e.name = CryptoHelper.decrypt(e.name).toString('utf-8');
          }
        });
        data.payload.files.forEach((entry) => {
          if (entry.name != null) {
            const e = entry;
            e.name = CryptoHelper.decrypt(e.name).toString('utf-8');
          }
        });
        this.folders = data.payload.folders;
        this.files = data.payload.files;
        this.accessRight = data.payload.accessRightType;
        this.initDragDrop();
        this.resizeHandler();
      } catch (error) {
        console.error(error);
      }
      return null;
    },
    async addFolder() {
      const t = this.$store.getters.tenant;
      if (t != null && t.subscriptionType === 'EXPIRED') {
        alert(this.$t('error.code_903'));
        return null;
      }
      this.folderToEdit = { inheritsRights: this.currentFolder != null, accessRights: [] };
      this.$refs.createOrEditFolderModal.show();
      return null;
    },
    async deleteFolder(folderId) {
      if (await this.$refs.confirmDeleteModal.show()) {
        try {
          const url = `folder/${folderId}`;
          await ServiceHelper.deleteDataFromBackend(url);
          this.loadFolderContent();
        } catch (error) {
          console.error(error);
        }
      }
      return null;
    },
    async editFolder(folder) {
      const t = this.$store.getters.tenant;
      if (t != null && t.subscriptionType === 'EXPIRED') {
        alert(this.$t('error.code_903'));
        return null;
      }
      this.folderToEdit = JSON.parse(JSON.stringify(folder));
      this.$refs.createOrEditFolderModal.show();
      return null;
    },
    openFolder(folder) {
      this.parentFolder = this.currentFolder;
      const f = folder;
      f.parentFolder = this.currentFolder;
      this.currentFolder = folder;
      this.loadFolderContent();
    },
    async deleteFile(fileId) {
      if (await this.$refs.confirmDeleteModal.show()) {
        try {
          const url = `file/${fileId}`;
          await ServiceHelper.deleteDataFromBackend(url);
          this.loadFolderContent();
        } catch (error) {
          console.error(error);
        }
      }
      return null;
    },
    async editFile(file) {
      try {
        const t = this.$store.getters.tenant;
        if (t != null && t.subscriptionType === 'EXPIRED') {
          alert(this.$t('error.code_903'));
          return;
        }
        const newFileName = await this.$refs.renameFileModal.show(file.name);
        if (newFileName != null && newFileName.trim().length > 0) {
          const url = `file/${file._id}`;
          const encFileName = CryptoHelper.encrypt(newFileName);
          await ServiceHelper.sendDataToBackend(url, { name: encFileName }, 'PUT');
          this.loadFolderContent();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async prepareFile(file) {
      const data = await ServiceHelper.loadDataFromBackend(`file/${file._id}`);
      const encData = data.payload.content;
      const decData = CryptoHelper.decryptToBuffer(encData);

      const blob = new Blob([decData], { type: data.payload.type });
      const objUrl = URL.createObjectURL(blob);
      return objUrl;
    },
    async downloadFile(file) {
      try {
        const objUrl = await this.prepareFile(file);
        const link = document.createElement('a');
        link.setAttribute('href', objUrl);
        link.setAttribute('download', file.name);
        document.body.appendChild(link); // Required for FF
        link.click(); // This will download the data file
        link.remove();
      } catch (error) {
        console.error(error);
      }
    },
    async openFile(file, inNewTab) {
      try {
        const objUrl = await this.prepareFile(file);
        if (inNewTab) {
          window.open(objUrl);
        } else {
          window.location.href = objUrl;
        }
      } catch (error) {
        console.error(error);
      }
    },
    goUp() {
      if (this.currentFolder.parentFolder != null) {
        this.currentFolder = this.currentFolder.parentFolder;
      } else {
        this.currentFolder = null;
      }
      this.loadFolderContent();
    },
  },
  computed: {
  },
};
</script>

<style scoped>
@import url('../styles/list.module.css');

.exlporer {
  text-align: left;
}

.listWrapper .listItemWrapper:nth-child(odd) {
  background-color: var(--color_list_1);
}
.listWrapper .listItemWrapper:nth-child(even) {
  background-color: var(--color_list_2);
}
.listWrapper .listItemWrapper:last-of-type {
  margin-bottom: 100px;
}

.listWrapper .listItemWrapper:hover {
  background-color: var(--color_orange_half);
}

.listWrapper .listItemWrapper .explorerFolder {
  font-weight: 600;
}

.explorerWrapper {
  overflow-y: auto;
}

.spaceIndicator {
  float: right;
}

.uploadError {
  display: inline-block;
  margin: 16px 0px;
  font-weight: 600;
  border: 2px solid var(--color_orange);
  color: black;
  background-color: #f4995433;
  padding: 8px;
  max-width: calc(100% - 300px);
  width: auto;
  text-align: left;
}

@media (max-width: 800px) {
  .spaceIndicator {
    float: none;
  }
}
</style>
